/**
 * @param {string} str - string that you want to capitalize
 * */
export const capitalize = (str) => {
    if (str && typeof str === 'string') {
        return `${str.substring(0, 1).toUpperCase()}${str.substring(1).toLowerCase()}`
    }
    return ''
}

/**
 * Create a slug from the string provided
 * @param {string} str
 * */
export const slugify = (str) => {
    if (str) {
        // if there is / in the title it would make the url invalid
        // to prevent that remove / from the url
        str = str.replace('/', '')
        // remove % otherwise it would make the url invalid
        str = str.replace('%', '')
        return str.replace(/\s+/ig, '-').toLowerCase()
    }
    return ''
}
/**
 * Show salary in a user friendly way
 * @param {number} min
 * @param {number} max
 * @param {string} salary_timeframe
 * */
export const showSalary = (min, max, salary_timeframe = '') => {
    if (min && max && salary_timeframe) {
        if (min === max) return `${min} USD/${salary_timeframe}`
        return `${min} - ${max} USD/${salary_timeframe}`
    } else {
        return 'DOE'
    }
}

export const bannerURL = () => {
    let url = 'http://tpmr.com/i/79715'
    if (window.location.host === 'lever.careerstaffing.net') {
        url = 'http://tpmr.com/i/79980'
    }
    return url
}

export const exploreJobSearchDomain = () => {
    const {host} = window.location
    return !!host.match(/explorejobsearch.com$/i)
}