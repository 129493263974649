import React, {useState} from 'react'
import {Modal, CloseButton} from 'react-bootstrap'

const popUpImg = require('./imgs/popup_jobdetail.png')
const PopUp = () => {
    const [show, setShow] = useState(true)

    return (
        <Modal show={show}
               onHide={() => setShow(false)}
               className="modal fade modal-bx-info editor">
            {/*<Modal.Header closeButton>
            </Modal.Header>*/}
            <Modal.Body style={{
                padding: '1rem'
            }}>
                <CloseButton
                    onClick={() => setShow(false)}
                    style={{
                        fontSize: 36,
                        marginBottom: '0.5rem',
                        marginTop: '-0.7rem'
                    }}/>
                <a href="https://jobs.remotejobsnearme.com/"
                   onClick={() => setShow(false)}
                   target="_blank">
                    <img src={popUpImg}
                         className="img-responsive"
                         alt="earn using survey"/>
                </a>
            </Modal.Body>
            {/*<Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Close
                </Button>
            </Modal.Footer>*/}
        </Modal>
    )
}

export default PopUp