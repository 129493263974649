import React from 'react'
import {Link, useHistory} from 'react-router-dom'
import {browseJobPath} from "../Markup"
import SmallLoader from "./SmallLoader"
import {capitalize} from "../../functions"

const Jobcategories = ({topCategories, loading}) => {
    const catIcons = [
        'ti-location-pin',
        'ti-wand',
        'ti-wallet',
        'ti-cloud-up',
        'ti-bar-chart',
        'ti-tablet',
        'ti-camera',
        'ti-panel'
    ]
    const history = useHistory()

    return (
        <div className="row sp20">
            <SmallLoader show={loading} center={true}/>
            {topCategories.map((top, i) => {
                return (
                    <div className="col-lg-3 col-md-6 col-sm-6"
                         style={{
                             cursor: 'pointer'
                         }}
                         onClick={() => history.push(`${browseJobPath}?&categories=${top?.category_id}`)}
                         key={i}>
                        <div className="icon-bx-wraper">
                            <div className="icon-content">
                                <div className="icon-md text-primary m-b20">
                                    <i className={catIcons?.[i]}/>
                                </div>
                                <span className="dez-tilte">
                                    {capitalize(top?.category?.name)}
                                </span>
                                <p className="m-a0">{top?.post_count} Open Positions</p>
                                <div className="rotate-icon">
                                    <i className="ti-location-pin"/>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
            {/*<div className="col-lg-3 col-md-6 col-sm-6">
                <div className="icon-bx-wraper">
                    <div className="icon-content">
                        <div className="icon-md text-primary m-b20"><i className="ti-location-pin"/></div>
                        <Link to={"/company-manage-job"} className="dez-tilte">Design, Art & Multimedia</Link>
                        <p className="m-a0">198 Open Positions</p>
                        <div className="rotate-icon"><i className="ti-location-pin"/></div>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="icon-bx-wraper">
                    <div className="icon-content">
                        <div className="icon-md text-primary m-b20"><i className="ti-wand"/></div>
                        <Link to={"/company-manage-job"} className="dez-tilte">Education Training</Link>
                        <p className="m-a0">198 Open Positions</p>
                        <div className="rotate-icon"><i className="ti-wand"/></div>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="icon-bx-wraper">
                    <div className="icon-content">
                        <div className="icon-md text-primary m-b20"><i className="ti-wallet"/></div>
                        <Link to={"/company-manage-job"} className="dez-tilte">Accounting / Finance</Link>
                        <p className="m-a0">198 Open Positions</p>
                        <div className="rotate-icon"><i className="ti-wallet"/></div>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="icon-bx-wraper">
                    <div className="icon-content">
                        <div className="icon-md text-primary m-b20"><i className="ti-cloud-up"/></div>
                        <Link to={"/company-manage-job"} className="dez-tilte">Human Resource</Link>
                        <p className="m-a0">198 Open Positions</p>
                        <div className="rotate-icon"><i className="ti-cloud-up"/></div>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="icon-bx-wraper">
                    <div className="icon-content">
                        <div className="icon-md text-primary m-b20"><i className="ti-bar-chart"/></div>
                        <Link to={"/company-manage-job"} className="dez-tilte">Telecommunications</Link>
                        <p className="m-a0">198 Open Positions</p>
                        <div className="rotate-icon"><i className="ti-bar-chart"/></div>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="icon-bx-wraper">
                    <div className="icon-content">
                        <div className="icon-md text-primary m-b20"><i className="ti-tablet"/></div>
                        <Link to={"/company-manage-job"} className="dez-tilte">Restaurant / Food Service</Link>
                        <p className="m-a0">198 Open Positions</p>
                        <div className="rotate-icon"><i className="ti-tablet"/></div>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="icon-bx-wraper">
                    <div className="icon-content">
                        <div className="icon-md text-primary m-b20"><i className="ti-camera"/></div>
                        <Link to={"/company-manage-job"} className="dez-tilte">Construction / Facilities</Link>
                        <p className="m-a0">198 Open Positions</p>
                        <div className="rotate-icon"><i className="ti-camera"/></div>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="icon-bx-wraper">
                    <div className="icon-content">
                        <div className="icon-md text-primary m-b20"><i className="ti-panel"/></div>
                        <Link to={"/company-manage-job"} className="dez-tilte">Health</Link>
                        <p className="m-a0">198 Open Positions</p>
                        <div className="rotate-icon"><i className="ti-panel"/></div>
                    </div>
                </div>
            </div>*/}
            <div className="col-lg-12 text-center m-t30">
                <Link className="site-button radius-xl"
                      to={browseJobPath}>
                    All Categories
                </Link>
            </div>
        </div>
    )
}

export default Jobcategories