export const placeholder_animation = () => {
    // Placeholder Animation Start
    const inputSelector = document.querySelectorAll('input, textarea')

    for (let i = 0; i < inputSelector.length; i++) {
        inputSelector[i].addEventListener('focus', function () {
            return this.parentElement.parentElement.classList.add("focused")
        })
    }


    for (let i = 0; i < inputSelector.length; i++) {
        inputSelector[i].addEventListener('blur', function () {
            const inputValue = this.value
            if (inputValue === '') {
                this.parentElement.parentElement.classList.remove('filled')
                this.parentElement.parentElement.classList.remove('focused')
            } else {
                this.parentElement.parentElement.classList.add('filled')
            }
        })
    }

    // Placeholder Animation End
}