import React, {useEffect, useState} from 'react'
import {placeholder_animation} from "../../../functions"
import {useHistory} from 'react-router-dom'
import Province from "../../Element/Province";
import City from "../../Element/City";

const SearchForm = () => {
    useEffect(() => {
        placeholder_animation()
    }, [])

    const [formData, setFormdata] = useState({})
    const history = useHistory()
    const onSubmit = (e) => {
        e.preventDefault()

        const query = new URLSearchParams(formData ?? {})
        history.push(`/browse-job-filter-list?&${query.toString()}`)
    }

    return (
        <form className="dezPlaceAni" onSubmit={onSubmit}>
            <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <label>Job Title, Keywords, or Phrase</label>
                        <div className="input-group">
                            <input type="text"
                                   onChange={(e) => setFormdata({
                                       ...formData,
                                       search: e.target.value
                                   })}
                                   value={formData?.search ?? ''}
                                   className="form-control"
                                   placeholder=""/>
                            <div className="input-group-append">
                                <span className="input-group-text"><i className="fa fa-search"/></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <Province value={formData?.provinceId ?? ''}
                              onChange={(e) => {
                                  setFormdata({
                                      ...formData,
                                      cityId: '',
                                      provinceId: e.target.value
                                  })
                              }}/>
                </div>
                <div className="col-md-3">
                    <City value={formData?.cityId ?? ''}
                          crProvinceId={formData?.provinceId}
                          onChange={(e) => setFormdata({
                              ...formData,
                              cityId: e.target.value
                          })}/>
                </div>
                <div className="col-md-2">
                    <button type="submit"
                            className="site-button btn-block">
                        Find Job
                    </button>
                </div>
            </div>
        </form>
    )
}

export default SearchForm