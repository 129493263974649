import React, {useEffect} from 'react'
import Markup from './markup/Markup'
import './css/plugins.css'
import './css/style.css'
import './css/templete.css'
import './css/skin/skin-1.css'
import './plugins/slick/slick.min.css'
import './plugins/slick/slick-theme.min.css'
import './css/extension.scss'
import {exploreJobSearchDomain} from "./functions";

function App() {
    useEffect(() => {
        if (exploreJobSearchDomain()) document.title = 'ExploreJobSearch'
        else document.title = 'Careerstaffing'
    }, [])

    return (
        <div className="App">
            <Markup/>
        </div>
    )
}

export default App
