import React from 'react'
import {Link} from 'react-router-dom'
import './scss/header.scss'
import {exploreJobSearchDomain} from "../../functions"

let logo = require('../../images/careerstaffing_logo.png')
const exploreJobSearch = exploreJobSearchDomain()
if (exploreJobSearch) {
    logo = require('../../images/explorejobsearch_header_logo.png')
}

const Header = () => {
    return <header className="site-header mo-left header fullwidth">
        <div className="sticky-header main-bar-wraper navbar-expand-lg">
            <div className="main-bar clearfix">
                <div className="container clearfix">

                    <div className="logo-header mostion">
                        <Link to={"/"}>
                            <img src={logo}
                                 className={`logo ${exploreJobSearch ? 'explorejobsearch-logo' : ''}`}
                                 alt="img"/>
                        </Link>
                    </div>

                    <div className="header-nav navbar-collapse collapse myNavbar justify-content-start"
                         id="navbarNavDropdown">
                        <ul className="nav navbar-nav"/>
                    </div>
                </div>
            </div>
        </div>
    </header>

}

export default Header

