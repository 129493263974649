import React, {useEffect, useState} from 'react'
import {Form} from "react-bootstrap";
import {api_axios} from "../../functions";

const City = ({onChange, value, crProvinceId}) => {
    const [cities, setCities] = useState([])
    // ph = placeholder
    const defaultCityPh = 'Select state to get cities'
    const [cityPh, setCityPh] = useState(defaultCityPh)

    useEffect(() => {
        if (crProvinceId >= 1) {
            setCityPh('Loading cities...')
            api_axios.get(`/values/city?province_id=${crProvinceId}`)
                .then(res => setCities(res?.data?.data ?? []))
                .then(() => setCityPh('City'))
        }
    }, [crProvinceId])

    return (
        <div className="form-group">
            <Form.Control
                as="select"
                custom
                onChange={onChange}
                value={value}
                className="select-btn">
                <option value="">{cityPh}</option>
                {cities.map(city => (
                    <option value={city?.id} key={city?.id}>{city?.name}</option>
                ))}
            </Form.Control>
        </div>
    )
}

export default City