import React from 'react'
import SurveyBanner from "./SurveyBanner"
import PropTypes from 'prop-types'
import {showSalary} from "../../../functions";

const SideInfo = ({job, loading}) => {
    const province = job?.city?.province?.name ?? ''
    const country = job?.city?.province?.country?.name ?? ''
    const {name: jobType} = job?.job_type ?? {}
    const category = (job?.categories ?? []).map(e => e?.category?.name).join(', ')
    const city = job?.city?.name ?? ''
    let remote = job?.remote ?? ''
    remote = remote?.toLowerCase()
    if (remote === 'full') {
        remote = 'Full time remote'
    } else {
        remote = null
    }

    // noinspection RequiredAttributes
    return (
        <>
            <div className="widget bg-white p-lr20 p-t20  widget_getintuch radius-sm side-info">
                <h4 className="text-black font-weight-700 p-t10 m-b15">
                    Job Details
                </h4>
                <ul>
                    <li>
                        <i className="fa fa-building"/>
                        <strong
                            className="font-weight-700 text-black">Company</strong>
                        <span className="text-black-light">
                        {job?.company?.name ?? ''}
                    </span>
                    </li>
                    <li>
                        <i className="fa fa-suitcase"/>
                        <strong
                            className="font-weight-700 text-black">Type</strong>
                        <span className="text-black-light">
                        {jobType}
                    </span>
                    </li>
                    {remote?.length >= 1 ? (
                        <li>
                            <i className="fa fa-building" style={{top: 0}}/>
                            <strong className="font-weight-700 text-black">Remote</strong>
                            {/*<span className="text-black-light">
                                {remote}
                        </span>*/}
                        </li>
                    ) : null}
                    <li>
                        <i className="ti-location-pin"/>
                        <strong
                            className="font-weight-700 text-black">Location</strong>
                        <span className="text-black-light">
                        {city}, {province}, {country}
                    </span>
                    </li>
                    <li>
                        <i className="ti-money"/>
                        <strong
                            className="font-weight-700 text-black">Salary</strong>
                        <span className="text-black-light">
                        {showSalary(job?.salary_min, job?.salary_max, job?.salary_timeframe)}
                    </span>
                    </li>
                    <li>
                        <i className="ti-shield"/><strong
                        className="font-weight-700 text-black">Category</strong> {category}
                    </li>
                </ul>
            </div>
            {loading ? null : <SurveyBanner/>}
        </>
    )
}

SideInfo.defaultProps = {
    job: {},
    loading: false
}

SideInfo.propTypes = {
    job: PropTypes.object,
    loading: PropTypes.bool
}

export default SideInfo