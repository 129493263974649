import React, {useContext, useEffect, useState} from 'react'
import Accordsidebar from "../../Element/Accordsidebar"
import {useHistory} from "react-router-dom"
import {api_axios} from "../../../functions"
import Job from "../../Element/Job"
import {FormDataContext, jobLocation} from "./Browsejobfilterlist";
import OverlayLoader from "../../Element/OverlayLoader";
import ContentHeader from "./ContentHeader";
import Pagination from "../../Element/Pagination";

const ContentBody = () => {
    const {formData} = useContext(FormDataContext)
    const [jobs, setJobs] = useState([])
    const [loading, setLoading] = useState(false)
    const [totalJobCount, setTotalJobCount] = useState(null)
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)

    const history = useHistory()
    useEffect(() => {
        const siteUrl = window.location.origin
        setLoading(true)
        const query = new URLSearchParams({
            'search': formData?.search ?? '',
            'province_id': formData?.provinceId ?? '',
            'city_id': formData?.cityId ?? '',
            'categories': formData?.categories ?? '',
            'last_days': formData?.timeframe ?? '',
            'per_page': formData?.perPage ?? 25
        })
        const queryStr = query.toString()

        api_axios.get(`/job-listing?site=${siteUrl}&page=${page}&${queryStr}`)
            .then(res => {
                const data = res?.data?.data ?? {}
                setJobs(data?.data ?? [])
                setTotalJobCount(data?.total ?? null)
                setLastPage(data?.last_page ?? null)
            })
            .catch(error => {
                console.log(error)
                alert('Error finding jobs')
            })
            .then(() => setLoading(false))
    }, [
        page,
        formData.search,
        formData.provinceId,
        formData.categories,
        formData.cityId,
        formData.timeframe,
        formData.perPage,
        history
    ])

    const onPageChange = (pageNo) => {
        if (parseInt(pageNo) !== page) {
            setPage(pageNo)
            window.location = jobLocation
        }
    }

    return (
        <div className="content-block" id="jobs-list">
            <div className="section-full browse-job p-b50">
                <div className="container">
                    <div className="row">
                        <Accordsidebar/>
                        <div className="col-md-8 jobs-cont">
                            <ContentHeader totalJobCount={totalJobCount}/>
                            <ul className="post-job-bx">
                                <OverlayLoader show={loading}/>
                                {jobs.map((job, i) => <Job job={job} key={i} hideDescription={true}/>)}
                            </ul>
                            <div className="pages-cont">
                                <Pagination currentPage={page}
                                            center={true}
                                            onChange={onPageChange}
                                            totalPages={lastPage}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentBody