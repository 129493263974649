import React, {useContext, useEffect, useState} from 'react'
import {FormDataContext} from "../components/browse-jobs/Browsejobfilterlist"
import Province from "./Province"
import City from "./City"

const Jobfindbox = () => {
    const {formData, setFormdata} = useContext(FormDataContext)

    const [inputValue, setInputValue] = useState('')
    const searchVal = formData?.search ?? ''
    useEffect(() => {
        setInputValue(searchVal)
    }, [searchVal])

    useEffect(() => {
        // start search when the user stops typing
        // credit https://stackoverflow.com/questions/42217121/how-to-start-search-only-when-user-stops-typing
        const delayDebounceFn = setTimeout(() => {
            setFormdata({
                ...formData,
                search: inputValue
            })
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [inputValue])

    return (
        <div className="section-full browse-job-find">
            <div className="container">
                <div className="find-job-bx">
                    <form className="dezPlaceAni job-find-box" onSubmit={(e) => e.preventDefault()}>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="form-group">
                                    <div className="input-group">
                                        <input type="text"
                                               value={inputValue}
                                               onChange={e => setInputValue(e.target.value)}
                                               placeholder="Job Title, Keywords, or Phrase"
                                               className="form-control"/>
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <i className="fa fa-search"/>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <Province value={formData?.provinceId ?? ''}
                                          onChange={e => setFormdata({
                                              ...formData,
                                              cityId: '',
                                              provinceId: e.target.value
                                          })}/>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <City crProvinceId={formData?.provinceId ?? ''}
                                      onChange={e => setFormdata({
                                          ...formData,
                                          cityId: e.target.value
                                      })}
                                      value={formData?.cityId ?? ''}/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Jobfindbox