import React, {createContext, useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import Header from '../../Layout/Header'
import Footer from '../../Layout/Footer'
import Jobfindbox from '../../Element/Jobfindbox'
import ContentBody from "./ContentBody"
import './scss/style.scss'

export const jobLocation = '#jobs-list' // the location in the page where the jobs list exist

const bnr = require('../../../images/banner/bnr1.jpg')

export const FormDataContext = createContext(undefined)

const Browsejobfilterlist = () => {
    const query = new URLSearchParams(window.location.href)
    let cat = query.get('categories')
    if (cat) {
        // only keep the valid IDS.
        // categories value would comma separated string of ids
        // id can only be a number
        cat = cat.split(',').filter(e => Number(e) >= 1).join(',')
    }

    const [formData, setFormdata] = useState({
        search: query.get('search'),
        cityId: query.get('cityId'),
        provinceId: query.get('provinceId'),
        categories: cat,
    })

    const history = useHistory()
    useEffect(() => {
        const {pathname} = window.location
        // remove any query param from the URL
        history.push(`${pathname}`)
    }, [])

    return (
        <FormDataContext.Provider value={{formData, setFormdata}}>
            <div className="browse-jobs page-wraper">
                <Header/>
                <div className="page-content bg-white">

                    <div className="dez-bnr-inr overlay-black-middle" style={{backgroundImage: "url(" + bnr + ")"}}>
                        <div className="container">
                            <div className="dez-bnr-inr-entry">
                                <h1 className="text-white">Browse Job Filter List</h1>

                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to={"/"}>Home</Link></li>
                                        <li>Browse Job Filter List</li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>

                    <Jobfindbox/>
                    <ContentBody/>
                </div>
                <Footer/>
            </div>
        </FormDataContext.Provider>
    )
}

export default Browsejobfilterlist