import React from 'react'
import FooterSubForm from "./FooterSubForm"
import './scss/footer.scss'
import {exploreJobSearchDomain} from "../../functions";

let logo = require("./../../images/footer_logo.png")
if (exploreJobSearchDomain()) {
    logo = require("./../../images/explorejobsearch_footer_logo.png")
}

const Footer = () => {
    return (
        <footer className="site-footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="widget">
                                <img src={logo}
                                     width="180"
                                     className="footer-logo"
                                     alt="logo at the footer"/>
                                <p className="text-capitalize m-b20">
                                    To get updates on latest Jobs posted delivered to your email, subscribe to our
                                    newsletter.
                                </p>
                                <div className="subscribe-form m-b20">
                                    <FooterSubForm/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
								<span>
                                    © Copyright by All rights reserved.
								</span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer