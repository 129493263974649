import React, {useState} from 'react'
import {api_axios} from "../../functions"
import {Alert} from "react-bootstrap"

/**
 * Form to subscribe to email list for jobs newsletter
 * */
const FooterSubForm = () => {
    const [email, setEmail] = useState('')
    const [subscribing, setSubscribing] = useState(false)
    const [subscribed, setSubscribed] = useState(false)

    const onSubmit = (e) => {
        e.preventDefault()
        setSubscribing(true)
        setSubscribed(false)
        api_axios.post('/add/email-list', {
            'email': email
        })
            .then(() => {
                setSubscribed(true)
                setTimeout(() => {
                    setSubscribed(false)
                }, 5000)
                setEmail('')
            })
            .catch(() => alert('Something went wrong'))
            .then(() => setSubscribing(false))
    }

    return (
        <form className="dzSubscribe"
              onSubmit={onSubmit}>
            <div className="dzSubscribeMsg"/>
            <div className="input-group">
                <input name="dzEmail"
                       value={email}
                       required
                       onChange={(e) => setEmail(e.target.value)}
                       className="form-control"
                       placeholder="Your Email Address"
                       type="email"/>
                <span className="input-group-btn">
                    <button name="submit"
                            value="Submit"
                            disabled={subscribing}
                            type="submit"
                            className="site-button radius-xl">
                        {subscribing ? 'Subscribing...' : 'Subscribe'}
                    </button>
                </span>
            </div>
            <Alert style={{
                marginTop: '1rem',
                fontSize: '0.9rem',
                backgroundColor: 'white',
                color: 'black',
                display: subscribed ? 'block' : 'none'
            }}>
                You will be notified of our new job postings
            </Alert>
        </form>
    )
}
export default FooterSubForm