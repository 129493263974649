import axios from 'axios'

const env = process.env.NODE_ENV
let baseURL = 'https://poster.remotejobsnearme.net/api/v1/'
if (env === 'development') {
    baseURL = 'http://localhost:8000/api/v1/'
}

export const api_axios = axios.create({
    baseURL: baseURL,
    headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
    }
})