import React, {useContext} from 'react'
import {FormDataContext} from "./Browsejobfilterlist";

const ContentHeader = ({totalJobCount}) => {
    const {formData, setFormdata} = useContext(FormDataContext)

    return (
        <div className="job-bx-title clearfix" id="job-box-title">
            <h6 className="font-weight-700 pull-left text-uppercase">
                {(totalJobCount !== null && totalJobCount !== undefined) ? `${totalJobCount} Jobs Found` : ''}
            </h6>
            <div className="float-right right-side-filter">
                <select className="custom-btn per-page-select"
                        value={formData?.perPage ?? 25}
                        onChange={e => setFormdata({
                            ...formData,
                            perPage: e.target.value
                        })}
                        style={{width: 150}}>
                    <option value="25">Jobs Per Page</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                </select>
                <select className="custom-btn timeframe-select"
                        value={formData.timeframe ?? ''}
                        onChange={e => setFormdata({
                            ...formData,
                            timeframe: e.target.value
                        })}>
                    <option value="">
                        Timeframe
                    </option>
                    <option value="60">Last 60 days</option>
                    <option value="30">Last 30 days</option>
                    <option value="7">Last 7 days</option>
                    <option value="3">Last 3 Days</option>
                </select>
            </div>
        </div>
    )
}

export default ContentHeader