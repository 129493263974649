import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import Header from '../../Layout/Header'
import Footer from '../../Layout/Footer'
import {api_axios} from "../../../functions"
import SmallLoader from "../../Element/SmallLoader"
import './scss/style.scss'
import BottomSurvey from "./BottomSurvey"
import ApplyButton from "./ApplyButton"
import SideInfo from "./SideInfo"
import Schema from "./Schema"
import Error404 from "../Error404"
import PopUp from "./PopUp";

const Jobdetail = () => {
    const {id} = useParams()
    const [settings, setSettings] = useState([])
    const [job, setJob] = useState({})
    const [jobNotExist, setJobNotExist] = useState(false)
    const [loadingJob, setLoadingJob] = useState(false)
    const city = job?.city?.name ?? ''
    let description = job?.description ?? ''
    // sometimes there would be unnecessary such p tags with break causing unnecessary gaps. Remove them
    description = description.replace('<p><br></p>', '')

    useEffect(() => {
        setLoadingJob(true)
        const siteUrl = window.location.origin
        const uri = `/job-listing/${id}?site=${siteUrl}`
        api_axios.get(uri)
            .then(res => setJob(res?.data?.data ?? {}))
            .catch(error => {
                if (error?.response?.status === 404) setJobNotExist(true)
            })
            .then(() => setLoadingJob(false))

        api_axios.get('settings')
            .then(res => setSettings(res?.data?.data ?? []))
    }, [id])

    if (jobNotExist) return <Error404 mainTitle="Job not found"/>
    else
        return (
            <>
                <div className="page-wraper job-detail">
                    <Schema job={job}/>
                    <Header/>
                    <div className="page-content bg-white">
                        <div className="content-block">
                            {loadingJob ? (
                                <SmallLoader show={loadingJob} center/>
                            ) : (
                                <div className="section-full">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="sticky-top">
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-6">
                                                            {loadingJob ? null :
                                                                <SideInfo job={job} loading={loadingJob}/>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-8">
                                                <div className="job-info-box">
                                                    <h3 className="m-t0 m-b10 font-weight-700 title-head">
                                                        {job?.title}
                                                    </h3>
                                                    <ul className="job-info">
                                                        <li title="job location">
                                                            <i className="ti-location-pin text-black m-r5"/> {city}
                                                        </li>
                                                    </ul>
                                                    <ApplyButton job={job}/>
                                                    <h5 className="font-weight-600 job-des-headline">Job
                                                        Description</h5>
                                                    <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"/>
                                                    <p dangerouslySetInnerHTML={{__html: description}}/>
                                                    {!loadingJob ? <BottomSurvey/> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <Footer/>
                </div>
                {settings.find(e => e.name === 'careerstaffing_job_page_pop_up'  && e.value === 'on') ? <PopUp/> : null}
            </>
        )
}
export default Jobdetail