import React from 'react'

const ApplyButton = ({job}) => {
    return (
        <a href={job?.application_url ?? ''}
           target="_blank"
           rel="noopener noreferrer"
           className="site-button apply-btn">
            Apply This Job
        </a>
    )
}

export default ApplyButton