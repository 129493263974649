import React from "react";
import './scss/overlay.scss'

const OverlayLoader = ({show}) => {
    return show ? (
        <div className="overlay-loader">
            <div className="overlay-loader-inner">
                <div className="overlay-loader-content"><span className="overlay-loader-spinner"/></div>
            </div>
        </div>
    ) : null
}

OverlayLoader.defaultProps = {
    show: false
}

export default OverlayLoader