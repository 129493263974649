import React, {useEffect, useState} from 'react'
import {Form} from "react-bootstrap";
import {api_axios} from "../../functions";

const Province = ({value, onChange}) => {
    const [provinces, setProvinces] = useState([])
    const defaultProvincePlaceholder = 'State'
    const [provincePlaceholder, setProvincePlaceholder] = useState(defaultProvincePlaceholder)

    useEffect(() => {
        setProvincePlaceholder('Loading states...')
        api_axios.get('/values/province/us')
            .then(res => setProvinces(res.data.data ?? []))
            .catch(error => console.log(error.response))
            .then(() => setProvincePlaceholder(defaultProvincePlaceholder))
    }, [])

    return (
        <div className="form-group">
            <Form.Control
                as="select"
                custom
                onChange={onChange}
                value={value}
                className="select-btn">
                <option value="">{provincePlaceholder}</option>
                {provinces.map(province => (
                    <option value={province.id} key={province.id}>{province.name}</option>
                ))}
            </Form.Control>
        </div>
    )
}

export default Province