import React from 'react'
import {Alert} from "react-bootstrap"
import {exploreJobSearchDomain} from "../../../functions";

const BottomSurvey = () => {
    return (
        <Alert variant="primary"
               style={{
                   fontSize: '0.9rem',
                   marginTop: '1rem'
               }}>
            While you're waiting to hear from the employer, if you're interested
            in surveys, <a href={exploreJobSearchDomain() ? 'http://tpmr.com/i/82712' : 'http://tpmr.com/i/79715'}
                           target="_blank" rel="noopener noreferrer">click here</a>
        </Alert>
    )
}

export default BottomSurvey