import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Homepage from './components/homepage/Homepage1';
import Jobdetail from './components/jobdetail/Jobdetail';
import Browsejobfilterlist from './components/browse-jobs/Browsejobfilterlist';
import ScrollToTop from './Element/ScrollToTop';

export const browseJobPath = '/browse-job-filter-list'

class Markup extends Component {
    render() {
        return (
            <BrowserRouter>
                <div className="page-wraper">
                    <Switch>
                        <Route path='/' exact component={Homepage}/>
                        <Route path='/job-detail/:id/:title?' exact component={Jobdetail}/>
                        <Route path={browseJobPath} exact component={Browsejobfilterlist}/>
                    </Switch>
                </div>
                <ScrollToTop/>
            </BrowserRouter>
        )
    }

}

export default Markup;