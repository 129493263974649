import React, {useState, useEffect, useContext} from 'react'
import {Link} from 'react-router-dom'
import {Accordion, Card} from 'react-bootstrap'
import {api_axios} from "../../functions"
import {FormDataContext, jobLocation} from "../components/browse-jobs/Browsejobfilterlist"

const Accordsidebar = () => {
    const query = new URLSearchParams(window.location.href)
    const catIDStr = query.get('categories')
    // only keep the valid IDS.
    // categories value would comma separated string of ids
    // id can only be a number
    const defaultSelected = catIDStr ? catIDStr.split(',').filter(e => Number(e) >= 1) : []

    const [selected, setSelected] = useState(defaultSelected)
    const [categories, setCategories] = useState([])

    useEffect(() => {
        api_axios.get('/values/category')
            .then(res => setCategories(res?.data?.data ?? []))
    }, [])

    const onCheck = (e) => {
        const {value} = e.target
        if (selected.find(e => e === value)) {
            setSelected(selected.filter(e => e !== value))
        } else {
            setSelected([
                ...selected,
                value
            ])
        }
    }

    const {formData, setFormdata} = useContext(FormDataContext)
    useEffect(() => {
        setFormdata({
            ...formData,
            'categories': selected.join(',')
        })
    }, [selected])

    const onReset = () => {
        setFormdata({})
        window.location = jobLocation
        setSelected([])
    }

    let activeCategory = false
    if (window.innerWidth >= 981) activeCategory = true // on large screen, keep the category expanded

    if (query.get('categories')) activeCategory = true

    let emptyForm = false
    /**
     * Get the values from the form data
     * If the array is 0, that means it doesn't have any data
     * */
    const values = Object.values(formData ?? {}).filter(e => e)
    if (values.length === 0) emptyForm = true
    return (
        <div className="col-md-4 m-b30 accordion-cont">
            <aside id="accordion1" className="sticky-top sidebar-filter bg-white">
                {/* category accordion key is 0. Make sure to keep 0 as string. Otherwise, it doesn't work
                * Keeping defaultActiveKey = '0' would expand the category part of the accordion
                */}
                <Accordion defaultActiveKey={activeCategory ? '0' : '-1'}>
                    {emptyForm ? null : (
                        <button className="btn btn-default reset-button"
                                onClick={onReset}>
                            Reset All Search
                        </button>
                    )}
                    <h6 className="title">
                        <i className="fa fa-sliders m-r5"/> Refined By
                    </h6>
                    <Accordion.Toggle as={Card} eventKey="0">
                        <div className="acod-head">
                            <h6 className="acod-title">
                                <Link data-toggle="collapse" to="#">Category</Link>
                            </h6>
                        </div>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                        <div id="companies" className="acod-body collapse show">
                            <div className="acod-content">
                                {categories.map(category => {
                                    const categoryID = category?.id
                                    const name = category?.name
                                    if (!name) return null
                                    return (
                                        <div className="custom-control custom-checkbox"
                                             key={categoryID}>
                                            <input className="custom-control-input"
                                                   id={`category-${categoryID}`}
                                                   type="checkbox"
                                                   checked={selected.find(e => Number(e) === Number(categoryID))}
                                                   value={categoryID}
                                                   onChange={onCheck}
                                                   name="checkbox-category"/>
                                            <label className="custom-control-label" htmlFor={`category-${categoryID}`}>
                                                {name}
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </Accordion.Collapse>
                </Accordion>
            </aside>
        </div>
    )
}

export default Accordsidebar