// noinspection JSUnresolvedVariable

import React from 'react'
import {Link} from "react-router-dom"
import './scss/job.scss'
import {showSalary, slugify} from "../../functions";

const Job = ({job, hideDescription}) => {
    const jobId = job?.id
    const city = job?.city?.name
    let title = job?.title
    if (title) {
        // some titles have /\ in the title. Replace it
        title = title.replace('\\', '')
            .replace('/', '')
            .replace('&amp;', '&')
            .replace(/\s{2,}/i, ' ')
            .trim()
    }
    const {name: state} = job?.city?.province ?? {}
    const {name: jobType} = job?.job_type ?? {}
    const jobCategories = job?.categories ?? []
    const category = jobCategories.slice(0, 2).map(e => e?.category?.name).join(', ') + (jobCategories.length > 2 ? '...' : '')

    const companyLogo = job?.company?.logo_full_path ?? ''
    const companyName = job?.company?.name ?? ''
    let logo;
    if (companyLogo) logo = <img alt="Company logo" src={companyLogo}/>

    if (!logo && companyName) {
        logo = <span className="company-name-logo">{companyName.substr(0, 1).toUpperCase()}</span>
    }

    return (
        <li>
            <Link to={`/job-detail/${jobId}/${slugify(job?.title)}`}>
                <div className="post-bx">
                    <div className="d-flex m-b30">
                        <div className="job-post-company">
                            <span>{logo}</span>
                        </div>
                        <div className="job-post-info">
                            <h4>{title}</h4>
                            <ul>
                                <li>
                                    <i className="fa fa-map-marker"/> {city} {state ? `, ${state}` : ''}
                                </li>
                                <li><i className="fa fa-bookmark-o"/> {jobType}
                                </li>
                                <li>
                                    <i className="fa fa-clock-o"/> {job?.posted_at}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="d-flex info-row">
                        <div className="job-time mr-auto">
                            {companyName ? (
                                <span title="Company name">
                                    <i className="fa fa-building"/> {companyName ?? ''}
                                </span>
                            ) : null}
                        </div>
                        <div className="job-time mr-auto">
                            <span
                                title={`job category ${jobCategories.length >= 3 ? `${jobCategories.length} categories are there` : ''}`}>
                                {category}
                            </span>
                        </div>
                        <div className="salary-bx" style={{minWidth: 130}}>
                        <span title="salary">
                            {showSalary(job?.salary_min, job?.salary_max, job?.salary_timeframe)}
                        </span>
                        </div>
                    </div>
                    {hideDescription ? null : (
                        <div className="d-flex">
                        <span style={{
                            padding: '0.3rem 0 0',
                            textAlign: 'justify'
                        }}>
                            {job?.short_description}
                        </span>
                        </div>
                    )}
                </div>
            </Link>
        </li>
    )
}

export default Job