import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {browseJobPath} from "../Markup"
import {api_axios} from "../../functions"
import Job from "./Job"
import SmallLoader from "./SmallLoader"
import Pagination from "./Pagination"
import './scss/jobsection.scss'

const browseJobBtn = () => {
    return (
        <div className="align-self-end browse-btn">
            <Link to={browseJobPath} className="site-button button-sm">
                Browse All Jobs <i className="fa fa-long-arrow-right"/>
            </Link>
        </div>
    )
}

const Jobsection = () => {
    const [jobs, setJobs] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)

    useEffect(() => {
        const siteUrl = window.location.origin
        setLoading(true)
        api_axios.get(`/job-listing?site=${siteUrl}&per_page=25&page=${page}`)
            .then(res => {
                let jobData = res?.data?.data?.data ?? [] // actual jobs are returned into this key
                setJobs(jobData)
                setTotalPage(res?.data?.data?.last_page ?? 1) // the last page is the total no of pages
            })
            .then(() => setLoading(false))
    }, [page])

    return (
        <div className="section-full bg-white content-inner-2 homepage-jobs">
            <div className="container">
                <div className="d-flex job-title-bx section-head">
                    <div className="mr-auto">
                        <h2 className="m-b5">Recent Jobs</h2>
                        <h6 className="fw4 m-b0">50+ Recently Added Jobs</h6>
                    </div>
                    {browseJobBtn()}
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <ul className="post-job-bx browse-job">
                            <SmallLoader show={loading} center/>
                            {jobs.map(job => <Job key={job?.id}
                                                  hideDescription={true}
                                                  job={job}/>)}
                        </ul>
                    </div>
                    <div className="col-lg-12">
                        <SmallLoader show={loading && jobs.length} center/>
                        <div className="section-bottom">
                            <Pagination totalPages={totalPage}
                                        center={true}
                                        onChange={pageNo => setPage(parseInt(pageNo) !== page ? pageNo : page)}
                                        currentPage={page}/>
                            {browseJobBtn()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Jobsection