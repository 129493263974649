import React from 'react'
import {Helmet} from "react-helmet"

const Schema = ({job}) => {
    let {
        title,
        description,
        salary_min,
        salary_max,
        salary_timeframe,
        salary_currency,
        job_type,
        company,
        city,
        date_posted_iso8601
    } = job

    const posted = date_posted_iso8601
    let salaryUnitText = ''
    // eslint-disable-next-line default-case
    switch (salary_timeframe?.toLowerCase()) {
        case 'hourly':
        case 'hour':
            salaryUnitText = 'HOUR'
            break
        case 'weekly':
        case 'week':
            salaryUnitText = 'WEEK'
            break
        case 'monthly':
        case 'month':
            salaryUnitText = 'MONTH'
            break
    }
    let salaryData = {
        "@type": "QuantitativeValue",
        "minValue": salary_min,
        "maxValue": salary_max,
        "unitText": salaryUnitText
    }

    let employmentType
    switch (job_type?.name?.toLowerCase()) {
        case 'part-time':
            employmentType = 'PART_TIME'
            break
        case 'full-time':
            employmentType = 'FULL_TIME'
            break
        case 'contract':
            employmentType = 'CONTRACTOR'
            break
        case 'temporary':
        case 'seasonal':
            employmentType = 'TEMPORARY'
            break
        case 'Full-time/Part-time':
            employmentType = ['FULL_TIME', 'PART_TIME']
            break
        case 'per diem':
            employmentType = 'PER_DIEM'
            break
        case 'internship':
        case 'intern':
            employmentType = 'INTERN'
            break
        default:
            employmentType = 'other'
    }

    let jsonData = {
        "@context": "http://schema.org",
        "@type": "JobPosting",
        "jobLocation": {
            "@type": "Place",
            "address": {
                "@type": "PostalAddress",
                "addressLocality": city?.name,
                "addressRegion": city?.province?.name,
                "addressCountry": city?.province?.country?.name
            }
        },
        "jobLocationType": "TELECOMMUTE",
        "datePosted": posted,
        "description": description,
        "employmentType": employmentType,
        "title": title,
        "identifier": {
            "@type": "PropertyValue",
            "propertyID": "jobID",
            "value": job?.id
        },
        "hiringOrganization": {
            "@type": "Organization",
            "name": company?.name,
        }
    }

    if (salary_min && salary_max && salary_timeframe) {
        jsonData.baseSalary = {
            "@type": "MonetaryAmount",
            "currency": salary_currency?.toUpperCase() ?? '',
            "value": salaryData
        }
    }

    if (company?.url) jsonData.hiringOrganization.sameAs = company?.url
    if (company?.logo_full_path) jsonData.hiringOrganization.logo = company?.logo_full_path

    return (
        <Helmet>
            <meta name="description" content="Helmet application"/>
            <script type="application/ld+json">
                {JSON.stringify(jsonData)}
            </script>
        </Helmet>
    )
}

export default Schema