import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import Header from '../../Layout/Header'
import Footer from '../../Layout/Footer'
import CountUp from 'react-countup'
import Jobcategories from '../../Element/Jobcategories'
import Jobsection from '../../Element/Jobsection'
import SearchForm from "./SearchForm"
import {browseJobPath} from "../../Markup";
import './scss/style.scss'
import {api_axios, bannerURL} from "../../../functions";
import SmallLoader from "../../Element/SmallLoader";

const bnr1 = require('../../../images/main-slider/slide2.jpg')
const wdrBnr = require('../../../images/banner/bnr4.gif')

const Homepage = () => {
    const [stats, setStats] = useState({})
    const [loadingStat, setLoadingStat] = useState(false)

    useEffect(() => {
        setLoadingStat(true)
        api_axios.get('job-stats')
            .then(res => setStats(res?.data?.data ?? []))
            .catch(error => console.log(error))
            .then(() => setLoadingStat(false))
    }, [])
    const totalJobCount = stats?.jobs_count ?? null;


    return (
        <div className="page-wraper homepage">
            <Header/>

            <div className="page-content">
                <div className="dez-bnr-inr dez-bnr-inr-md" style={{backgroundImage: "url(" + bnr1 + ")"}}>
                    <div className="container">
                        <div className="dez-bnr-inr-entry align-m">
                            <div className="find-job-bx">
                                <Link to={browseJobPath} className="site-button button-sm">Find Jobs, Employment &
                                    Career Opportunities</Link>
                                <h2>Search Between More Then <br/> <span className="text-primary">50,000</span> Open
                                    Jobs.</h2>
                                <SearchForm/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-full job-categories content-inner-2 bg-white">
                    <div className="container">
                        <div className="section-head d-flex head-counter clearfix">
                            <div className="mr-auto">
                                <h2 className="m-b5">Popular Categories</h2>
                                <h6 className="fw3">20+ Categories work waiting for you</h6>
                            </div>
                            {totalJobCount > 0 ? (
                                <div className="head-counter-bx">
                                    <h2 className="m-b5 counter"><CountUp end={totalJobCount} duration={1}/></h2>
                                    <h6 className="fw3">Jobs Posted</h6>
                                </div>
                            ) : (loadingStat ? <SmallLoader/> : null)}
                        </div>
                        <Jobcategories topCategories={stats?.top_categories ?? []}
                                       loading={loadingStat}/>
                        <div className="wide-banner">
                            <a href={bannerURL()}
                               rel="noopener noreferrer"
                               target="_blank">
                                <img src={wdrBnr}
                                     className="img-fluid wide-banner-img"
                                     alt="banner to go to remotejobsnearme.com"/>
                            </a>
                        </div>
                    </div>
                </div>

                <Jobsection/>
            </div>
            <Footer/>
        </div>
    )
}

export default Homepage