import React from 'react'
import PropTypes from "prop-types"
import {Link} from "react-router-dom"
import './scss/pagination.scss'

const Pagination = ({onChange, currentPage: page, totalPages: total, center, showPagCount}) => {
    const renderPages = () => {
        const start = total > 4 && page > 3 ? page - 2 : 1
        const pages = Array(total > 5 ? 5 : total)
            .fill(start, 0)
            .map((e, i) => start + i <= total ? start + i : null)
            .filter(e => e)

        return pages.map((e, i) => (
            <li className="page" key={i}>
                <Link to={"#"}
                      className={e === page ? 'active' : ''}
                      key={e}
                      onClick={() => onChange(e)}>
                    {e}
                </Link>
            </li>
        ))
    }

    return (
        <div className={`pagination-cont app-pagination ${center ? 'center-align' : ''}`}>
            {(total && showPagCount) ? (
                <div className="page-counter">
                    Page {page} of {total}
                </div>
            ) : null}
            <div className="pagination-bx">
                <ul className="pagination">
                    <li className="previous">
                        <Link to={"#"}
                              onClick={() => onChange(page > 1 ? page - 1 : page)}>
                            <i className="ti-arrow-left"/> Prev
                        </Link>
                    </li>
                    {renderPages()}
                    <li className="next">
                        <Link to={"#"}
                              onClick={() => onChange(total)}>
                            Next <i className="ti-arrow-right"/>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}


Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    onChange: PropTypes.func,
    center: PropTypes.bool,
    showPagCount: PropTypes.bool
}


export default Pagination