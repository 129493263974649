import React from 'react'
import {bannerURL} from "../../../functions";

const bnr = require('../../../images/banner/bnr5.gif')

const SurveyBanner = () => {
    return (
        <div className="tall-banner-cont">
            <a href={bannerURL()}
               rel="noopener noreferrer"
               target="_blank">
                <img src={bnr}
                     className="img-fluid banner-img"
                     alt="tall banner to go to remotejobsnearme.com"/>
            </a>
        </div>
    )
}

export default SurveyBanner