import React from "react"
import "./scss/loader.css"

const SmallLoader = ({marginLeft, style, show, center}) => {
    if (marginLeft) {
        style = {
            ...style,
            marginLeft: 10
        }
    }

    let divStyle = {}
    if (center) {
        divStyle = {
            ...divStyle,
            textAlign: 'center',
            display: 'block',
            margin: '0 auto'
        }
    }
    return (
        <>
            {show ? (
                <div style={divStyle}>
                    <div className="lds-ellipsis" style={style}>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            ) : null}
        </>
    )
}


export default SmallLoader